import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BaseLayout from './layouts/BaseLayout';
import EarlyAccessPage from './pages/EarlyAccessPage';
import TestComponent from './components/TestComponent';
import IntroPage from './pages/IntroPage';
import ProductPage from './pages/ProductPage';
import FeaturesPage from './pages/FeaturesPage';
import PricingPage from './pages/PricingPage';
import StayUpdatedPage from './pages/StayUpdatedPage';

const App: React.FC = () => {
  return (
    <Router>
      <BaseLayout>
        <Routes>
          <Route path="/" element={<IntroPage />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/contact" element={<StayUpdatedPage />} />
          <Route path="/early-access" element={<EarlyAccessPage />} />
          <Route path="/test" element={<TestComponent />} />
        </Routes>
      </BaseLayout>
    </Router>
  );
};

export default App;
