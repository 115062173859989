import React from 'react';
import './Footer.css';
import TextField from './TextField';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaTiktok } from 'react-icons/fa';
import Xlogo from '../assets/logo-black.png'

const Footer: React.FC = () => {
    return (
        <footer className="footer-section">
            <div className="footer-content">
                <div className="footer-links">
                    <TextField text="Quick Links" weight="bold" size={18} style={{ color: '#e9002f' }} />
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#about">About Us</a></li>
                        <li><a href="#features">Features</a></li>
                        <li><a href="#contact">Contact</a></li>
                        <li><a href="#terms">Terms of Service</a></li>
                        <li><a href="#privacy">Privacy Policy</a></li>
                    </ul>
                </div>
                <div className="footer-social">
                    <TextField text="Follow Us" weight="bold" size={18} style={{ color: '#e9002f' }} />
                    <div className="social-icons">
                        <a href="https://www.linkedin.com/company/mixstream/"><FaLinkedinIn /></a>
                        <a href="https://www.facebook.com"><FaFacebookF /></a>
                        <a href="https://www.instagram.com"><FaInstagram /></a>
                        <a href="https://x.com/mixstream_"><FaTwitter /></a>
                        <a href="https://www.tiktok.com"><FaTiktok /></a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <TextField text={`© ${new Date().getFullYear()} Mixstream. All rights reserved.`} size={14} />
            </div>
        </footer>
    );
};

export default Footer;
