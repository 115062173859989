import { useState } from 'react';

export const useHubspotSubscription = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const subscribeEmail = async (email: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('/api/create-contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Subscription failed');
      }

      return data;
    } catch (err: any) {
      setError(err.message || 'An unknown error occurred');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { subscribeEmail, isLoading, error };
};